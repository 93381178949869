

















import BaseButton from '@/components/base/BaseButton.vue';
import MemberPage from '@/layouts/MemberLayout/MemberPage.vue';
import { computed, defineComponent } from '@/plugins/composition';
import { routeNames } from '@/router/routeNames';
import Calendar from './Calendar/Calendar.vue';
import GettingStarted from './GettingStarted/GettingStarted.vue';
import MenuTopic from './Menu/Menu.vue';

export default defineComponent({
  components: { MemberPage, BaseButton, GettingStarted, MenuTopic, Calendar },
  name: 'HelpPage',
  setup() {
    const components = computed(() => {
      const comps = require.context('./components', false, /[A-Z]\w+\.(vue|js)$/);

      return comps.keys().map((x) => comps(x).default || comps(x));
    });

    return {
      routeNames,
      components,
    };
  },
});
