













import { defineComponent } from '@/plugins/composition';

export default defineComponent({
  name: 'FaqTopic',
  props: {
    title: {
      type: String,
    },
  },
});
