


















import { defineComponent } from '@/plugins/composition';
import FaqTopic from '../components/FaqTopic.vue';
import ArtistPass from './ArtistPass.vue';
import MakeABooking from './MakeABooking.vue';
import MakeRepeatBooking from './MakeRepeatBooking.vue';
import ModifyDelete from './ModifyDelete.vue';
import Payment from './Payment.vue';
import TypeOfSpace from './TypeOfSpace.vue';
import ViewOnly from './ViewOnly.vue';

export default defineComponent({
  components: {
    FaqTopic,
    ViewOnly,
    ArtistPass,
    TypeOfSpace,
    MakeABooking,
    MakeRepeatBooking,
    Payment,
    ModifyDelete,
  },
  name: 'GettingStarted',
});
