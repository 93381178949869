

















import { defineComponent } from '@/plugins/composition';
import FaqTopic from '../components/FaqTopic.vue';
import ChangingProfile from './ChangingProfile.vue';
import GettingHelp from './GettingHelp.vue';
import LoggingIn from './LoggingIn.vue';
import MyProfile from './MyProfile.vue';
import Registering from './Registering.vue';
import ResettingPassword from './ResettingPassword.vue';

export default defineComponent({
  components: { FaqTopic, Registering, LoggingIn, ResettingPassword, ChangingProfile, MyProfile, GettingHelp },
  name: 'GettingStarted',
});
